var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mapbox-gl-control property-control",style:([_vm.offsetStyle, _vm.posStyle])},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"left":_vm.attachLeft,"offset-x":"","z-index":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"left":_vm.attachLeft,"right":!_vm.attachLeft},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-1 ma-2",attrs:{"height":"auto","min-width":"0","color":!_vm.selectedNone ? 'primary darken-1' : 'white',"small":"","disabled":_vm.options.length === 0},on:{"click":_vm.buttonClick}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("local_offer")])],1)]}}],null,true)},[_c('span',[_vm._t("tooltip",function(){return [_vm._v("Properties")]})],2)])]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"outlined":"","flat":""}},[_c('v-card-text',[_vm._t("default"),_c('v-layout',{style:(_vm.layoutStyle)},[_c('v-autocomplete',{attrs:{"value":_vm.selectedOptions,"items":_vm.options,"color":"primary darken-1","item-color":"primary darken-1","dense":"","chips":"","small-chips":"","label":"Select properties","multiple":"","clearable":"","hide-details":"","loading":_vm.dataPending,"placeholder":"None selected","open-on-clear":false,"filled":""},on:{"change":_vm.updateSelectedOptions},scopedSlots:_vm._u([(_vm.options.length > 1)?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleOptions}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedOptions.length > 0 ? 'primary darken-1' : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null,{key:"selection",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"color":parent.itemColor,"small":parent.smallChips}},[_c('span',{staticClass:"ellipsis-chip-label"},[_vm._v(_vm._s(item.text))]),(parent.deletableChips)?_c('v-icon',{attrs:{"small":"","right":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("cancel")]):_vm._e()],1):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption mr-1"},[_vm._v("(+"+_vm._s(_vm.selectedOptions.length - 1)+" others)")]):_vm._e()]}}],null,true)})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }